import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('YOUR_LICENSE_KEY');

function App() {
  return (
    <></>
  );
}

export default App;
